import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import './../styles/rooms.css';
import Connector from '../handlers/connector';
import { useHistory } from 'react-router-dom';
import {SOCKET_IO_URL } from '../constants/constants';
import io from 'socket.io-client';

const Rooms = () => {

    let socket = null;
    const history = useHistory();
    const [availableRooms, setAvailableRooms] = useState([]);

    useEffect(() => {

        try {

            if(!Connector.getInstance().user_name) {
                history.push("/");
                return;
            }

            fetchRooms();
         
        } catch(e) {
            console.log('[Rooms:useEffect] exception ' + e);
        }
    
      }, []);

      const fetchRooms = () => {
          try {

            socket = io(SOCKET_IO_URL);
            socket.on('connect', onSocketConnected);
            socket.on('fetch-rooms', onFetchRooms);
            
          } catch(e) {
            console.log('[Rooms:fetchRooms] exception ' + e);
          }
      }

      const onSocketConnected = () => {

        try {

            console.log('[Rooms:onSocketConnected]');

            socket.emit('fetch-rooms');


        } catch(e) {
            console.log('[Connector:onSocketConnected] Exception ' + e);
        }
    }

    const onFetchRooms = (data) => {

        try {

            let list = [];

            Object.entries(data).forEach(([key, value]) => {
                list.push(value);
            });
       
            console.dir(list);
            setAvailableRooms(list);

            socket.close();
            
        } catch(e) {
            console.log('[Rooms:onFetchRooms] Exception ' + e);
        }
    }

    const joinMeeting = (map) => {

        try {

            console.dir(map);

            Connector.getInstance().room = map.room;
            history.push("/Home?room=" + map.room);

        } catch(e) {
            console.log('[Rooms:onFetchRooms] Exception ' + e);
        }
    }

    return(
        <>
            <div className = "join-room-help-text">
                <h2>Running Sessions</h2>
            </div>
            <React.Fragment>
                {availableRooms.map((map) => (
                    <div className="room-preview">
                        <h2>{map.name}</h2>
                        <button onClick = {() => joinMeeting(map)}>Join</button>
                    </div>
                ))}
            </React.Fragment>
        </>
    );
}

export default Rooms

