import React, { useState, useEffect} from 'react';
import '../styles/videoControls.css'


import Mic from '../images/microphone.png';
import Mute from '../images/mute.png';
import Video from '../images/video.png';
import VideoDisabled from '../images/videoDisabled.png';
import FullScreen from '../images/fullscreen.png';
import Record from '../images/record.png';
import Screenshot from '../images/screenshot.png';
import Stop from '../images/stop.png';
import Expand from '../images/expand.png';
import Whiteboard from '../images/whiteboard.png';
import WhiteboardDisabled from '../images/whiteboarddisabled.png';
import Grid from '../images/grid.png'
import GridDisabled from '../images/griddisabled.png'
import Whiteboard_min from '../images/whiteboard_min.png'
import Whiteboard_max from '../images/whiteboard_max.png'
import Chat from '../images/chat.png'
import ChatDisabled from '../images/chatDisabled.png'
import Connector from '../handlers/connector';
import {reportWindowSize} from '../handlers/connector';
import { useHistory } from 'react-router-dom';

const VideoChatControls = (props) => {

    const [number, setNumber] = useState(0);
    const [isMicMuted, setIsMicMuted] = useState(false);
    const [isWebCamDisabled, setIsWebCamDisabled] = useState(false);
    const [whiteBoard, setWhiteBoard] = useState(false);
    const [gridView, setGridView] = useState(false);
    const [isWhiteboardFullscreen, setWhiteboardScreen] = useState(false);
    //const [isChatActive, setIsChatActive] = useState(false);
    const [teacher, setTeacher] = useState(Connector.getInstance().is_teacher);
    const elem = document.documentElement;
    const history = useHistory();
    
    useEffect(() => {

        try {
          
            Connector.getInstance().registerVideoControlsUIReloadListener(reloadUI);
            
        } catch(e) {
            console.log('[SelfVideo:VideoChatControls] exception ' + e);
        }

        props.changeChatStatus(Connector.getInstance().isChatActive);
    
    }, []);
    

    const micToggle = () => {
        try {
            if(isMicMuted){
                setIsMicMuted(false);
                Connector.getInstance().local_stream.getAudioTracks()[0].enabled = true;
            } else {
                setIsMicMuted(true);
                Connector.getInstance().local_stream.getAudioTracks()[0].enabled = false;
            }
        } catch(e) {
            console.log('[SelfVideo:micToggle] exception ' + e);
        }
    }

    const webCamToggle = () => {

        try {
            if(isWebCamDisabled){
                setIsWebCamDisabled(false);
                Connector.getInstance().local_stream.getVideoTracks()[0].enabled = true;
            } else {
                setIsWebCamDisabled(true);
                Connector.getInstance().local_stream.getVideoTracks()[0].enabled = false;
            }
        } catch(e) {
            console.log('[SelfVideo:webCamToggle] exception ' + e);
        }

    }

    const reloadUI = () => {
        
        setNumber(number + 1);
        setWhiteBoard(isWhiteboardActive());
        setGridView(isGridActive());
    }

    const toggleWhiteboard = () => {
        Connector.getInstance().toggleWhiteboard();
        reportWindowSize();
    }

    const toggleGirdView = () => {
        Connector.getInstance().is_gridview_active = !Connector.getInstance().is_gridview_active;
        console.log('Connector.getInstance().is_gridview_active ' + Connector.getInstance().is_gridview_active);
        Connector.getInstance().reloadUI();
        reportWindowSize();
    }

    const isWhiteboardActive = () => {
        return Connector.getInstance().isWhiteboardActive();
    }

    const isGridActive = () => {
        return Connector.getInstance().isGridActive();
    }
    
    const toggleFullscreen = () => {

        try {

            if (
                document.fullscreenElement || /* Standard syntax */
                document.webkitFullscreenElement || /* Safari and Opera syntax */
                document.msFullscreenElement /* IE11 syntax */
            ) {
                closeFullscreen();
            } else {
                openFullscreen();
            }

        } catch(e) {
            console.log('[SelfVideo:toggleFullscreen] exception ' + e);
        }
    }

    const toggleWhiteBoardFullScreen = () => {
     
        try {

            Connector.getInstance().whiteBoardFullScreen = !Connector.getInstance().whiteBoardFullScreen;
            reportWindowSize();
            setWhiteboardScreen(Connector.getInstance().whiteBoardFullScreen);

        } catch(e) {
            console.log('[SelfVideo:toggleFullscreen] exception ' + e);
        }

    }

    const openFullscreen = () => {
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
    }

    const closeFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
        }
    }

    const hangup = () => {
        
        try {

            Connector.getInstance().stop();
            
        } catch(e) {
            console.log('[SelfVideo:hangup] exception ' + e);
        }

    }

    const toggleChat = () => {
        if(Connector.getInstance().isMobile()){
            Connector.getInstance().isChatActive = !Connector.getInstance().isChatActive;
            props.changeChatStatusMobile(!props.chatStatusMobile);
        } else {
            Connector.getInstance().isChatActive = !Connector.getInstance().isChatActive;
            props.changeChatStatus(Connector.getInstance().isChatActive);
        }
        Connector.getInstance().onChatWindowOpen();
        reportWindowSize();
    }

    console.log('reloadUI 11111111111111 teacher ' + teacher);

    const getUI = () => {
        let value = "" + teacher; 
        if(value == "true") {
            return (
                <div className = {props.chatStatus ? "video-controls-right-alignment-chat-active" : "video-controls-right-alignment"}>
                    <div className = {whiteBoard ? "video-controls-container-right" : "video-controls-container-right-whiteboard-inactive"}>
                        <div onClick = {() => toggleWhiteboard()}><img src={ !whiteBoard ? Whiteboard : WhiteboardDisabled } className="video-controls-whiteboard" style = {{paddingRight : whiteBoard ? '3vmin' : '0vmin'}}/></div>
                        { !whiteBoard &&
                            <div onClick = {() => toggleGirdView()}><img src={ !gridView ? Grid : GridDisabled } className="video-controls-grid" /></div> 
                        }
                        { Connector.getInstance().whiteBoardUrl && <div onClick={() => toggleWhiteBoardFullScreen()} >
                            <img src={!isWhiteboardFullscreen ? Whiteboard_max : Whiteboard_min } className= "video-controls-whiteboard-fullscreen" />
                        </div>}
                        <div onClick={() => toggleFullscreen()} >
                            <img src={FullScreen} className="video-controls-fullscreen"  />
                        </div>
                        <div onClick={() => hangup()} >
                            <img src={Stop} className="video-controls-stop" />
                        </div>


                        <div onClick={() => toggleChat()} >
                        <img src={props.chatStatus ? ChatDisabled : Chat} className="video-controls-chat"  />
                    </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className = {props.chatStatus ? "video-controls-right-alignment-chat-active" : "video-controls-right-alignment"}>
                <div className = "video-controls-container-right-whiteboard-inactive-one">
                    { !whiteBoard &&
                        <div onClick = {() => toggleGirdView()}><img src={ !gridView ? Grid : GridDisabled } className="video-controls-grid" /></div> 
                    }
                    { Connector.getInstance().whiteBoardUrl && <div onClick={() => toggleWhiteBoardFullScreen()} >
                        <img src={!isWhiteboardFullscreen ? Whiteboard_max : Whiteboard_min } className="video-controls-whiteboard-fullscreen" />
                    </div>}
                    <div onClick={() => toggleFullscreen()} >
                        <img src={FullScreen} className="video-controls-fullscreen"  />
                    </div>


                    <div onClick={() => toggleChat()} >
                        <img src={props.chatStatus ? ChatDisabled : Chat} className="video-controls-chat"  />
                    </div>
                </div>
            </div>    
            );
        }
    }

    /*

    const getStudentUI = () => {

        if(is_teacher) {
            alert('get student ui teacher ' + teacher);
            return (
                <div className = "video-controls-right-alignment">
                <div className = "video-controls-container-right-whiteboard-inactive-one">
                    { !whiteBoard &&
                        <div onClick = {() => toggleGirdView()}><img src={ gridView ? Grid : GridDisabled } className="video-controls-grid" /></div> 
                    }
                    { Connector.getInstance().is_whiteboard_active && <div onClick={() => toggleWhiteBoardFullScreen()} >
                        <img src={Stop} className="video-controls-whiteboard-fullscreen" />
                    </div>}
                    <div onClick={() => toggleFullscreen()} >
                        <img src={FullScreen} className="video-controls-fullscreen"  />
                    </div>
                </div>
            </div>    
            );
        }
    }
    */

    return(
    <>
        <div className = "video-controls-left-alignment">
            <div className="video-controls-container-left">
                <div onClick = {() => micToggle()}><img src={ isMicMuted ? Mute : Mic } className="video-controls-mic" /></div>
                <div onClick = {() => webCamToggle()}><img src={ isWebCamDisabled ? VideoDisabled : Video} className="video-controls-video" /></div>
            </div>
        </div>

        {getUI()}
    </>
    );
}

export default VideoChatControls
