import React, { useState, useEffect} from 'react';
import { Link, useHistory } from 'react-router-dom';
import Connector from '../handlers/connector';
import './../styles/login.css';

const Login = () => {

    const history = useHistory();
    const [userName, setUserName] = useState('');
    const [room, setRoom] = useState(null);

    useEffect(() => {

        try {
    
            let name = localStorage.getItem('name');
            console.log('name ' + name);
            if(name) {
                console.log('1111  name ' + name);
                setUserName(name);
            }
    
            const search = window.location.search;
            const params = new URLSearchParams(search);
            setRoom(params.get('room'));
            console.log('room ' + room);

        } catch(e) {
            console.log('[Home:useEffect] exception ' + e);
        }
    
      }, []);
    
   
    const startMeeting = () => {

        try {

            if(userName.trim() === "") {
                return;
            }

            Connector.getInstance().is_teacher = true;
            Connector.getInstance().user_name = userName;
            localStorage.setItem('name', userName);
            localStorage.setItem('teacher', "true");

            if(!Connector.getInstance().room) {
                Connector.getInstance().room = "" + Date.now() + "_" + Math.floor(Math.random() * 10000); // Make sure create dynamic room
            }

            history.push("/Home?room=" + Connector.getInstance().room);

        } catch(e) {
            console.log('[Login:startMeeting] exception ' + e);
        }
    }

    const joinMeeting = () => {

        try {

            if(userName.trim() === "") {
                return;
            }

            Connector.getInstance().is_teacher = false;
            Connector.getInstance().user_name = userName;
            localStorage.setItem('name', userName);
            localStorage.setItem('teacher', "false");

            const search = window.location.search;
            const params = new URLSearchParams(search);
            const room = params.get('room');
            Connector.getInstance().room = room;
            console.log('room ' + room);
            if(!room) {
                history.push("/Rooms"); 
            } else {
                history.push("/Home?room=" + Connector.getInstance().room);
            }

        } catch(e) {
            console.log('[Login:joinMeeting] exception ' + e);
        }
    }

    return(
        <>
            <div className = "credentials">
                <label>User Name : </label>
                <input
                    type='text'
                    required 
                    value = {userName}
                    onChange = {(e) => setUserName(e.target.value)}
                />
                { !room && <button onClick = {() => startMeeting()}>Start Meeting</button>}
                <button style={{margin:'10px'}} onClick = {() => joinMeeting()}>Join Meeting</button>
            </div>
        </>
    );
}

export default Login
