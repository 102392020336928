import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Login from './routes/Login';
import Rooms from './routes/Rooms';
import Home from './routes/Home';

ReactDOM.render(
    <Router>
      <Switch>
        <Route exact path = '/' component={Login} />
        <Route exact path = '/Rooms' component={Rooms} />
        <Route path = '/Home' component={Home} />
      </Switch>
    </Router>,
  document.getElementById('root')
);

