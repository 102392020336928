import React, { useState, useEffect } from 'react';
import Whiteboard from './../pages/Whiteboard';
import './../styles/whiteboard.css';
import Connector from '../handlers/connector';
import {reportWindowSize} from '../handlers/connector';
import VideoLayout from '../components/VideoLayout';
import VideoChatControls from '../components/VideoChatControls';
import Chat from '../components/Chat';
import './../styles/home.css';
import Iframe from 'react-iframe';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

function Home() {

  const [whiteBoard, setWhiteBoard] = useState(false);
  const [whiteBoardUrl, setWhiteBoardUrl] = useState(false);
  const [isChatActive, setIsChatActive] = useState(false);
  const [isChatActiveMobile, setIsChatActiveMobile] = useState(false);
  const [isMobile, setIsMobile] = useState(Connector.getInstance().isMobile());
  
  const [videoStyle, setVideoStyle] = useState({});
  const [whiteBoardStyle, setWhiteboardStyle] = useState({});
  const [url, setUrl] = useState(null);
  const history = useHistory();
  const [started, setStarted] = useState(false);

  useEffect(() => {

    try {


        setStarted(true);

        console.log('came here ----');

        if(!Connector.getInstance().user_name) {
         
          let name = localStorage.getItem('name');
          if(name) {
            let is_teacher = "" + localStorage.getItem('teacher');
            const search = window.location.search;
            const params = new URLSearchParams(search);
            const room = params.get('room');
            console.log('room ' + room);
            if(!room) {
              history.push("/");
              return
            }
            
            let key = localStorage.getItem('key');
            console.log('key ' + key);
            if(key != (room + '_' + name)) {
              history.push("/?room=" + room);
              return;
            }
            
            console.log('is_teacher ' + is_teacher);
            Connector.getInstance().is_teacher = is_teacher == "true" ? true : "false";
            Connector.getInstance().user_name = name;
            Connector.getInstance().room  = room;
            reportWindowSize();
            
          } else {

            const search = window.location.search;
            const params = new URLSearchParams(search);
            const room = params.get('room');
            console.log('room ' + room);
            if(room) {
              history.push("/?room=" + room);
              return;
            }
            
            history.push("/");
            return;
          }
        }

        localStorage.setItem('key', Connector.getInstance().room + '_' + Connector.getInstance().user_name);
      
        Connector.getInstance().connect();
        Connector.getInstance().start();
        Connector.getInstance().registerHomeStyleUpdate(updateVideoStyle);
        Connector.getInstance().registerSessionTerminatedUpdateListener(onSessionTerminated);
        updateSize();
        window.addEventListener('resize', updateSize);

    } catch(e) {
        console.log('[Home:useEffect] exception ' + e);
    }

  }, []);

  
  useEffect(() => {
    setIsChatActive(Connector.getInstance().isChatActive);
  }, [Connector.getInstance().isChatActive]);
  

  if(!started) {

    try {

      if(!Connector.getInstance().user_name) {
         
        let name = localStorage.getItem('name');
        if(name) {
          let is_teacher = localStorage.getItem('teacher');
          const search = window.location.search;
          const params = new URLSearchParams(search);
          const room = params.get('room');
          console.log('room ' + room);
          if(!room) {
            return
          }
          
          let key = localStorage.getItem('key');
          console.log('key ' + key);
          if(key != (room + '_' + name)) {
            return;
          }
          
          console.log('is_teacher ' + is_teacher);
          Connector.getInstance().is_teacher = is_teacher == "true" ? true : "false";
        } 
      }

    } catch(e) {
      console.log('[Home] exception ' + e);
    }
  }

  const onSessionTerminated = (is_teacher) => {
    try {

	    
     if(is_teacher == false || is_teacher === false) {
      	console.log('---- is_teacher ' + is_teacher);
     	alert("Session terminated by host");
      	history.push("/");
     } else {
      	history.push("/");
     }



    } catch(e) {
      console.log('[Home:onSessionTerminated] exception ' + e);
    }

  }

  const updateVideoStyle = (wrapPanel, whiteboardPanel) => {

    try {

      console.log('updateVideoStyle wrapPanel.style ' + JSON.stringify(wrapPanel.style));
      
      setVideoStyle(wrapPanel.style);
      setWhiteboardStyle(whiteboardPanel.style);
      
      setWhiteBoard(Connector.getInstance().is_whiteboard_active);
      setWhiteBoardUrl(Connector.getInstance().whiteBoardUrl);
    
    } catch(e) {
        console.log('[Home:updateVideoStyle] exception ' + e);
    }
  }

  const updateSize = () => {
    reportWindowSize();
  }

  const Mobile = () => {
    if(Connector.getInstance().isMobile()){
      setIsMobile(true);
      return isMobile;
    } else {
      setIsMobile(false);
      return isMobile;
    }
  }

  console.log('whiteBoard 1111 ' + whiteBoard + " , style " + JSON.stringify(whiteBoardStyle), isChatActive);

  // 
  return (
    <>
      <div>
          <div>
            <div className = "wrappanel" style={videoStyle}>
                  <VideoLayout/>
              </div>
            
              {(whiteBoard || whiteBoardUrl) &&
                <div className="wrappanel" style={whiteBoardStyle}>
                    <Iframe url={Connector.getInstance().whiteBoardUrl}
                  id="myId"
                  width="100%"
                    height="100%"
                  />
                </div>
              }

              {!isChatActiveMobile && <VideoChatControls chatStatus = { isChatActive } changeChatStatus = { setIsChatActive } changeChatStatusMobile = {setIsChatActiveMobile} chatStatusMobile = { isChatActiveMobile }/>}
              {isChatActive && <Chat chatStatusMobile = { isChatActiveMobile } changeChatStatusMobile = { setIsChatActiveMobile }/>} 
          </div>
      </div>
    </>
  );
}

export default Home;
